import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SectionLayout } from 'components/Sections/SectionLayout';
import styled from 'styled-components';
import ToolboxLayout from 'layouts/toolboxLayout';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import ContentfulCardsBlock from 'components/Contentful/ContentfulCardsBlock';
import Contentful2ColumnLayout from 'components/Contentful/Contentful2ColumnLayout';
import useToolsCards from 'hooks/useToolsCards';
import toolsIcon from 'images/tools-white.svg';
import BookmarkContext from 'utils/BookmarkContext';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import FilterTags from 'components/FilterTags';

const ToolsPage = (props) => {
  const allTools = useToolsCards();
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [tools, setTools] = useState(allTools);
  const dynamicHero = props.data.contentfulDynamicHero;
  const demoForm = props.data.contentful2ColumnLayout;

  useEffect(() => {
    const filteredTools = allTools.filter(
      (tool) =>
        !tool.industries ||
        tool.industries?.some((industry) => selectedIndustries.includes(industry)),
    );

    setTools(selectedIndustries.length ? filteredTools : allTools);
  }, [selectedIndustries]);

  const setIndustryTags = (tag) => {
    setSelectedIndustries(
      !tag
        ? []
        : selectedIndustries.includes(tag)
        ? selectedIndustries.filter((industry) => industry !== tag)
        : [...selectedIndustries, tag],
    );
  };

  const tags = [...new Set([].concat(...allTools.map((tool) => tool.industries)))].filter(
    (industry) => !!industry,
  );

  const cardsBlock = {
    bgColor: 'Transparent',
    cardType: 'Image',
    cards: tools,
  };

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Tools',
        icon: toolsIcon,
        url: '',
        hideInMobile: true,
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={props.data.contentfulSeo}
      layout="toolbox"
      {...props}
    >
      <ToolboxDynamicHero
        {...dynamicHero}
        images={[]}
        dataPosition="Hero"
        formType="Email Capture"
        customInlineForm={<HeroSubscribeForm buttonType="primary" theme="light" />}
      />
      <FilterContainer>
        <FilterTags
          tags={tags}
          title="Filter By Industry"
          selectedTags={selectedIndustries}
          setSelectedTags={(tag) => setIndustryTags(tag)}
          clearable
        />
      </FilterContainer>
      <ToolboxIconCardsSection>
        <BookmarkContext.Provider value={{ type: 'tool', subtitle: 'Tools' }}>
          <ContentfulCardsBlock {...cardsBlock} cols={3} />
        </BookmarkContext.Provider>
      </ToolboxIconCardsSection>
      <Contentful2ColumnLayout {...demoForm} pageType="normal" />
    </ToolboxLayout>
  );
};

export default ToolsPage;

export const ToolsPageQuery = graphql`
  query ToolsPageQuery {
    contentfulSeo(contentful_id: { eq: "5LQc6qMm5LpSUOVAgcEC6r" }) {
      ...Seo
    }

    contentfulDynamicHero(contentful_id: { eq: "7hBjWAkQntk4YEQv7aw21M" }) {
      ...DynamicHero
    }

    contentful2ColumnLayout(contentful_id: { eq: "6eGRiTW0cZlDmpyQNooRwX" }) {
      ...TwoColumnLayout
    }
  }
`;

const ToolboxDynamicHero = styled(ContentfulDynamicHero)`
  padding-top: 60px !important;
  min-height: 550px;

  .section-layout--inner-container {
    padding-top: 150px;

    @media (max-width: 575px) {
      padding-top: 100px;
    }
  }

  .hero-subscribe-form-container {
    margin-top: 40px;
    width: 100%;
    .data-layer-form {
      justify-content: center !important;
    }
  }

  @media (max-width: 680px) {
    height: auto;

    .hero-copy {
      margin-bottom: 0;
    }
  }
`;

const ToolboxIconCardsSection = styled.div`
  .section-layout--inner-container {
    padding-top: 0;
  }
  .card-container {
    margin-top: 0;
  }
  .st-cta-cards-section .card-row-container {
    margin-top: 0;

    .card-item {
      .card--cover {
        padding-top: 63.25%;

        .card--cover-wrapper {
          padding: 20px;
        }
      }
    }
  }
`;

const FilterContainer = styled(SectionLayout)`
  .section-layout--inner-container {
    padding-bottom: 0;
  }
  p {
    color: var(--darkest);
  }
`;
